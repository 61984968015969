import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { CalculatorUiComponent } from '../BuyInsuranceComponent';
import {
	addProp,
	buildExistingProductsDescription,
	buildCreditCardInfo,
	buildCurrentInsuranceInfo,
	buildPaymentInfo,
	handleOrder,
	homeFamilyHouseSpecificFields,
	buildGlobalSumsInfo,
} from '../services/OrderService';
import { formatNumber } from '../services/ValidationService';
import MapOrderFlow from '../types/MapOrderFlow';
import { getFormerInsuranceCompanies } from '../services/ProductService';

export default class MapOrderDescription extends MapOrderFlow {
	public constructor(uiComp: CalculatorUiComponent) {
		super(uiComp);
	}

	public async orderByEmail() {
		const specificFields = homeFamilyHouseSpecificFields(this.uiComp);
		await handleOrder(this.uiComp, 'House/Holidayhouse submitOrder', specificFields);
	}

	/** Called by CalculatorComponent */
	public buildDescriptionForEmail(desc: string) {
		const productCode =
			(this.uiComp as any).productName === InsurancesProductEnum.HUS_FORSIKRING ? 'HU_HP' : 'FH_HP';

		desc += '<h2>Person</h2>';
		const now: Date = new Date();
		desc += addProp(
			'Email sendt',
			`${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}, ${now.getDate()}/${
				now.getMonth() + 1
			}-${now.getFullYear()}`
		);
		desc += addProp('Navn', this.model.contact_information.name);
		if (this.model.personInfo.address) {
			desc += addProp('Hjem Adresse', `${this.model.personInfo.address}`);
		}
		if ((this.uiComp as any).productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING) {
			desc += addProp('Fritidshus adresse', `${this.model.personInfo.holidayAddress.address}`);
		}
		desc += addProp('Alder', this.model.personInfo.customerAge);
		if (this.model.contact_information.customerNo) {
			desc += addProp('KundeNr', this.model.contact_information.customerNo);
		} else {
			desc += addProp('Cpr', this.model.contact_information.cpr);
		}
		desc += addProp('Antal skader', this.model.additionalInfo.skader);
		desc += addProp('Kunde har accepteret betingelser', this.model.contact_information.accept ? 'Ja' : 'Nej');

		if (this.model.contact_information.accept) {
			const formerCompanies = getFormerInsuranceCompanies();
			if (formerCompanies.length > 0) {
				desc += addProp('Samtykke til selskab(er)', formerCompanies.join(', '));
			}
		}

		desc += addProp('Email', this.model.contact_information.email);
		desc += addProp('Telefonnummer', this.model.contact_information.phone);

		desc += buildExistingProductsDescription(this.model, this.cms);

		desc += buildCurrentInsuranceInfo(this.model);
		desc += '<h2>Ønsket forsikring</h2>';
		desc += addProp('Forsikringstype', this.pack.name);

		if ((this.uiComp as any).productName === InsurancesProductEnum.FRITIDSHUS_FORSIKRING && this.cms.insuredValue) {
			desc += addProp('Forsikringssum', formatNumber.format(this.cms.insuredValue) + ' kr.');
		}

		desc += addProp('Selvrisiko', (this.uiComp as any).getExcessAmount());

		desc += buildGlobalSumsInfo(this.model);

		desc += buildPaymentInfo(this.model, this.pack, productCode + ' beregning');

		desc += buildCreditCardInfo(this.model, this.cms);

		const params: Map<string, string> = new Map();
		params.set('description', desc);
		return params;
	}
}
