import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { key: "childrenCpr" }
const _hoisted_2 = { class: "text-left font--small mb-2" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inputFieldComponent = _resolveComponent("inputFieldComponent")!
  const _component_AddressSearchComponent = _resolveComponent("AddressSearchComponent")!
  const _component_checkboxComponent = _resolveComponent("checkboxComponent")!
  const _component_ButtonTapComponent = _resolveComponent("ButtonTapComponent")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_inputFieldComponent, {
      id: "frmNameA",
      name: "name",
      placeholder: "F.eks. Peter Petersen",
      label: "Navn",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      innerData: _ctx.model.contact_information,
      required: true,
      onInputFieldComponent: _ctx.setSubHeader,
      visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
      class: "text-left pb-3"
    }, null, 8, ["validateOnLoad", "innerData", "onInputFieldComponent", "visibleError"]), [
      [_vShow, !_ctx.model.contact_information.customerNo]
    ]),
    _createVNode(_component_inputFieldComponent, {
      id: "frmPhoneNumA",
      name: "phone",
      type: "tel",
      innerData: _ctx.model.contact_information,
      placeholder: " F.eks. 12 34 56 78",
      labelPopover: _ctx.cms.phoneNoPopOver,
      label: "Telefonnummer",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      required: true,
      visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
      class: "text-left pb-3"
    }, null, 8, ["innerData", "labelPopover", "validateOnLoad", "visibleError"]),
    _withDirectives(_createVNode(_component_inputFieldComponent, {
      id: "frmEmailC",
      name: "email",
      type: "email",
      placeholder: "F.eks. peter@mailtest.dk",
      label: "E-mail",
      validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
      required: true,
      innerData: _ctx.model.contact_information,
      visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
      class: "text-left pb-3"
    }, null, 8, ["validateOnLoad", "innerData", "visibleError"]), [
      [_vShow, _ctx.showEmail]
    ]),
    _createVNode(_TransitionGroup, { name: "slide-simple" }, {
      default: _withCtx(() => [
        _withDirectives(_createVNode(_component_AddressSearchComponent, {
          class: "text-left pb-4",
          id: "address",
          key: "address",
          elementId: "address",
          isOnlyZipSearch: false,
          label: _ctx.addressLabel,
          placeholder: "F.eks Gadenavn 29, 2.tv...",
          defaultValue: _ctx.model.personInfo.address,
          isValid: _ctx.model.personInfo.address !== undefined,
          visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
          onAddressSearch: _ctx.addressSearch
        }, null, 8, ["label", "defaultValue", "isValid", "visibleError", "onAddressSearch"]), [
          [_vShow, _ctx.emailFilled && _ctx.showAddress]
        ]),
        _withDirectives(_createVNode(_component_inputFieldComponent, {
          key: "cpr",
          id: "cpr",
          name: "cpr",
          innerData: _ctx.model.contact_information,
          placeholder: "10 cifre",
          label: "CPR-nummer",
          validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
          visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
          required: true,
          class: "text-left pb-3"
        }, null, 8, ["innerData", "validateOnLoad", "visibleError"]), [
          [_vShow, _ctx.emailFilled && !_ctx.model.contact_information.customerNo && _ctx.addressFilled]
        ]),
        (
					_ctx.hasChildrenCpr &&
					_ctx.emailFilled &&
					(_ctx.model.contact_information.customerNo ||
						_ctx.fieldValidator.isValidCpr(_ctx.model.contact_information.cpr)) &&
					_ctx.addressFilled
				)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.familyInfo.personCountId + 1, (inx) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: 'ch' + inx
                }, [
                  (_openBlock(), _createBlock(_component_inputFieldComponent, {
                    key: 'cc' + (inx - 1),
                    id: 'cpr' + (inx - 1),
                    name: 'cpr' + (inx - 1),
                    innerData: _ctx.model.familyInfo,
                    placeholder: "10 cifre",
                    label: 'CPR-nummer for ' + _ctx.model.familyInfo['name' + (inx - 1)],
                    validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
                    required: true,
                    fieldValidatorType: "cprUnderEighteen",
                    visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
                    class: "text-left pb-3"
                  }, null, 8, ["id", "name", "innerData", "label", "validateOnLoad", "visibleError"]))
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        (
					_ctx.hasAdultCpr &&
					_ctx.emailFilled &&
					(_ctx.model.contact_information.customerNo ||
						_ctx.fieldValidator.isValidCpr(_ctx.model.contact_information.cpr)) &&
					_ctx.addressFilled
				)
          ? (_openBlock(), _createBlock(_component_inputFieldComponent, {
              key: "otherCpr",
              id: "otherCpr",
              name: "otherCpr",
              innerData: _ctx.model.personInfo,
              placeholder: "10 cifre",
              label: 'CPR-nummer for ' + _ctx.model.personInfo.otherName,
              validateOnLoad: !_ctx.model.pristineStep.get(_ctx.card.name),
              required: true,
              fieldValidatorType: "cpr",
              visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
              class: "text-left pb-3"
            }, null, 8, ["innerData", "label", "validateOnLoad", "visibleError"]))
          : _createCommentVNode("", true),
        _withDirectives(_createVNode(_component_checkboxComponent, {
          key: "accept",
          id: "accept",
          name: "accept",
          preSelect: _ctx.model.contact_information.accept,
          inputValue: 'samtykke',
          class: "pb-3",
          visibleError: !_ctx.model.pristineStep.get(_ctx.card.name),
          validationErrorText: "Du mangler at give samtykke",
          onCheckboxComponent: _ctx.checkboxEvent
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", {
                innerHTML: _ctx.consent.text
              }, null, 8, _hoisted_3),
              (_ctx.consent.linkText)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(['show-hide-details', { open: _ctx.showConsentDetails }]),
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleConsentDetails && _ctx.toggleConsentDetails(...args))),
                    textContent: _toDisplayString((_ctx.showConsentDetails ? 'Skjul ' : 'Vis ') + _ctx.consent.linkText)
                  }, null, 10, _hoisted_4))
                : _createCommentVNode("", true),
              _createVNode(_Transition, { name: "slide-vertical-no-delay" }, {
                default: _withCtx(() => [
                  (_ctx.consent.detailsText && _ctx.showConsentDetails)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        innerHTML: _ctx.consent.detailsText
                      }, null, 8, _hoisted_5))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["preSelect", "visibleError", "onCheckboxComponent"]), [
          [_vShow, _ctx.showAcceptConsent && _ctx.emailFilled && _ctx.addressFilled]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_ButtonTapComponent, {
      class: _normalizeClass([_ctx.card.buttonStyle, 'mt-3', { 'btn-disabled': !_ctx.isValid }]),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.nextStep())),
      textContent: _toDisplayString(_ctx.card.linkText),
      "data-gtm": _ctx.cms.dataGtm,
      "data-gtm-click-text": _ctx.card.routePath
    }, null, 8, ["class", "textContent", "data-gtm", "data-gtm-click-text"])
  ]))
}