import InsurancesProductEnum from '@/enums/InsurancesProductEnum';
import { STEP } from '../services/UiStepService';
import { isDevelop } from '@/views/calculators/services/environmentService';
import {
	CommonModel,
	Model,
	PersonInfoCommon,
	mockAdditionalInfo,
	mockContactInformation,
	mockPersonInfo,
} from '../types/CommonModel';
import { cloneDeep } from 'lodash';

// list and order of steps
export const STEPS: Array<string> = [
	STEP.CHILDREN_INFO,
	STEP.PERSON_INFO,
	STEP.PACKAGE,
	STEP.ADDITIONAL_INFO,
	STEP.OVERVIEW,
	STEP.CONTACT_INFORMATION,
	STEP.PAYMENT,
	STEP.ORDER,
];

export const FamilyInfo6Persons = {
	personCount: undefined,
	personCountId: 0,
	subtitle: undefined,

	name0: undefined,
	age0: undefined,
	cpr0: undefined,
	hasWork0: undefined,
	work0: undefined,
	workId0: undefined,
	existInsurance0: undefined,
	existingInsurance0: undefined,
	existingInsuranceId0: undefined,

	name1: undefined,
	age1: undefined,
	cpr1: undefined,
	hasWork1: undefined,
	work1: undefined,
	workId1: undefined,
	existInsurance1: undefined,
	existingInsurance1: undefined,
	existingInsuranceId1: undefined,

	name2: undefined,
	age2: undefined,
	cpr2: undefined,
	hasWork2: undefined,
	work2: undefined,
	workId2: undefined,
	existInsurance2: undefined,
	existingInsurance2: undefined,
	existingInsuranceId2: undefined,

	name3: undefined,
	age3: undefined,
	cpr3: undefined,
	hasWork3: undefined,
	work3: undefined,
	workId3: undefined,
	existInsurance3: undefined,
	existingInsurance3: undefined,
	existingInsuranceId3: undefined,

	name4: undefined,
	age4: undefined,
	cpr4: undefined,
	hasWork4: undefined,
	work4: undefined,
	workId4: undefined,
	existInsurance4: undefined,
	existingInsurance4: undefined,
	existingInsuranceId4: undefined,

	name5: undefined,
	age5: undefined,
	cpr5: undefined,
	hasWork5: undefined,
	work5: undefined,
	workId5: undefined,
	existInsurance5: undefined,
	existingInsurance5: undefined,
	existingInsuranceId5: undefined,
};

export class AccidentChildrenModel extends CommonModel implements Model {
	public familyInfo = cloneDeep(FamilyInfo6Persons);

	public constructor() {
		super(STEPS, InsurancesProductEnum.BOERNEULYKKES_FORSIKRING);
	}

	public personInfo = new PersonInfoCommon();

	public static mock(model: AccidentChildrenModel) {
		if (isDevelop) {
			mockPersonInfo(model);

			Object.assign(model.familyInfo, {
				personCount: '3 børn',
				personCountId: 2,
				subtitle: '3 børn',
				age0: 0,
				cpr0: '1212201212',
				name0: 'Lisa Lisa',
				existInsurance0: 'nej',

				age1: 16,
				cpr1: '1212171214',
				name1: 'Peter Peter',
				existInsurance1: 'ja',
				existingInsurance1: 'Tryg Forsikring',

				age2: 16,
				cpr2: '1212191213',
				name2: 'Jens Jensen',
				existInsurance2: 'nej',
			});

			mockContactInformation(model);

			mockAdditionalInfo(model);
			Object.assign(model.additionalInfo, {
				existInsurance: undefined,
			});
		}
	}
}
