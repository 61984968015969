import { PersonInfoCommon } from '../types/CommonModel';
import AxiosService from './axiosService';

const axiosService = new AxiosService();
axiosService.setWithCredentials(false);

export const mapDawaAddress = async (personInfo: PersonInfoCommon, dawa: any) => {
	personInfo.address = dawa.matchText;
	personInfo.zipCode = dawa.data.postnr;
	personInfo.zipName = dawa.data.postnrnavn;
	personInfo.streetName = dawa.data.vejnavn;
	personInfo.houseNr = dawa.data.husnr;
	personInfo.floorNr = dawa.data.etage;
	personInfo.doorNr = dawa.data.dør;
	personInfo.streetCode = dawa.data.vejkode;
	const res = await axiosService.getQuery(dawa.data.href, {});
	personInfo.kvhxValue = res?.data?.kvhx || res?.data?.kvh;
	// DEBUG console.log('Address', personInfo);
};
export const resetAddress = (personInfo: PersonInfoCommon) => {
	personInfo.address = undefined;
	personInfo.zipCode = undefined;
	personInfo.zipName = undefined;
	personInfo.streetName = undefined;
	personInfo.houseNr = undefined;
	personInfo.floorNr = undefined;
	personInfo.doorNr = undefined;
	personInfo.kvhxValue = undefined;
	personInfo.streetCode = undefined;
};
