import Coverage from '../components/coverageComponent/Coverage';
import { STEP } from '../services/UiStepService';

export interface Model {
	personInfo: PersonInfoCommon | PersonInfoHomeFamily | PersonInfoAccident;
}
export class PersonInfoCommon {
	public subtitle?: string;
	public customerAge?: string;
	public address?: string;
	public zipCode?: string;
	public zipName?: string;
	public streetName?: string;
	public houseNr?: string;
	public floorNr?: string;
	public doorNr?: string;
	public kvhxValue?: string;
	public streetCode?: string;
	public almbrandCustomer?: 'ja | nej';
	public existingAlmBrandProducts: string[] = [];
	public familyMemberCount?: string;
	public familyMemberCountId?: number;
}

export class PersonInfoHomeFamily extends PersonInfoCommon {
	public thatchedRoof?: 'ja' | 'nej';
}

export class PersonInfoTravel extends PersonInfoCommon {
	public isOldestInHousehold?: 'ja' | 'nej';
	public oldestInHouseholdAge?: string;
}

export class PersonInfoAccident extends PersonInfoCommon {
	public customerAgeChecked = false;
	public work?: string;
	public workId?: string;
	public isMainPerson?: boolean;
	public otherName: string;
	public otherCustomerAge: string;
	public otherCpr: string;
}

export class Campaign {
	public ID?: string; // lowercase i.e. diba
	public description?: string;
	public splash?: string;
	public groupId?: number;
	public valid?: boolean;
	public init?: boolean;
	public hasContentPage?: boolean;
	public subtitle?: string;
	public uniqueKey?: string;
	public discount?: number;
	public discountDisplay?: string;
	public productStepDiscount?: number[];
	public tiaDiscount?: number;
	public includeDiscountPlusCustomer?: boolean;
	public originalDiscount?: number;
}

class ChoosePackage {
	public subtitle: string;
	public ownRiskId: number;
	public selectedPackage: PackageCalculation;
	public monthYear?: 'M' | 'Y';
	public useTiaOffer = false;
	public segmentedProductProperty?: string;
	public insuredValueAmount?: number;
	public insuredValue?: number;
}

class Overview {
	public subtitle?: string;
	public highlights?: string;
	public details?: string[];
}

export class Payment {
	public subtitle?: string;
	public regNo?: string;
	public kontoNo?: string;
	public userSelected?: boolean;
}

export class ContactInformationCommon {
	public subtitle?: string;
	public name?: string;
	public email?: string;
	public phone?: string;
	public cpr?: string;
	public customerNo?: string;
	public accept?: boolean;
}

export class AdditionalInfo {
	public subtitle?: string;
	public existInsurance?: 'ja' | 'nej';
	public existingInsurance?: string;
	public existingInsuranceId?: string;
	public existFormerInsurance?: 'ja' | 'nej';
	public formerInsurance?: string;
	public formerInsuranceId?: string;
	public validNow?: 'ja' | 'nej';
	public validFromDate?: string;
	public skader?: '0' | '1' | '2' | '3+';
	public rki?: 'ja' | 'nej';
}

export interface PackageCalculation {
	id: number;
	name?: string;
	specialDiscount?: number;
	statutoryFee?: number;
	basePrice?: number;
	totalPrice?: number;
	totalPriceDisplay?: string;
	yearlyBasePrice?: number;
	yearlyStatutoryFee?: number;
	yearlyPriceTotal?: number;
	yearlyPriceTotalDisplay?: string;
	currentPlusCostumerLevel?: string;
	pkPrices?: PkPrices;

	// accident child and family
	summedBasePrice?: number;
	summedStatutoryFee?: number; // not returned by api
	summedTotalPrice?: number;

	summedYearlyBasePrice?: number;
	summedYearlyStatutoryFee?: number; // not returned by api
	summedYearlyPriceTotal?: number;

	// family
	coverages: Array<Coverage>;
	expandedCoverages: Array<string>;
}

export interface PkPrice {
	price: number;
	display: string;
}

export interface PkPrices {
	monthly: {
		pk0?: PkPrice; // plusCustomer 0 price
		pk1?: PkPrice; // plusCustomer .95 price
		pk2?: PkPrice; // plusCustomer .90 price
		pk3?: PkPrice; // plusCustomer .85 price
	};
	yearly: {
		pk0?: PkPrice; // plusCustomer 0 price
		pk1?: PkPrice; // plusCustomer .95 price
		pk2?: PkPrice; // plusCustomer .90 price
		pk3?: PkPrice; // plusCustomer .85 price
	};
}

interface KeyValue {
	key: string;
	value: number;
}
export interface PackageConfig {
	id: number;
	name: string;
	description: string;
	includedCoverageIds: number[]; // KLK strings in accident
	includedCoveragesExpandedIds: number[]; // KLK strings in accident
	calculateRisksCar: number[];
	sums: { covId: string; sum: number }[];
	minAge: number;
	maxAge: number;
	coverages: Coverage[]; // accident
	expandedCoverages: string[];
}
export class CompleteCalculation {
	public isCalculated = false;
	public updatePrices = ['pr1', 'pr2', 'pr3'];
	public excessIdDefault: number;
	public packageIds: number[] = [];
	public packages: PackageCalculation[];
	public abCalc: {
		excesses: { id: number; minCustomerAge: number }[];
		packs: Map<number, PackageCalculation[]>;
		packageIds: number[];
		excessIdDefault: number;
		packages: PackageConfig[];
		// home, travel, family
		segmentedProductProperties: string[];
		familyMemberCountOptions: KeyValue[];
		calculated: boolean;
		coveragesDetails: Coverage[];
		// dog
		dogCountOptions: string[];
		dogCountLabels: string[];
		sickDiseaseCoverageFee: number;
		sickDiseaseCoveragePackageId: number;
		// family home
		insuredValueOptions: KeyValue[];
		// accident, child, family
		globalSums?: KeyValue[];
	};
	public allCoverages = new Map<number, Map<number, Array<Coverage>>>();
	public discount: number;
	public discountDisplay: string;
	public addressSupplement: number;
}

export class CommonModel {
	public constructor(steps: Array<string>, productName: string) {
		this.pristineStep = mapPristineSteps(steps);
		this.productName = productName;
	}
	public campaign = new Campaign();

	public choosePackage = new ChoosePackage();

	public overview = new Overview();

	public contact_information = new ContactInformationCommon();

	public additionalInfo = new AdditionalInfo();

	public payment = new Payment();

	public calculation = new CompleteCalculation();

	public pristineStep = new Map<string, boolean>();
	public productName: string;
	public ownRiskOptions: Array<number> = [];
	public ownRiskLabels: Array<string> = [];
	public updatedExcessListFromProduct = false;
	public showReceipt = false;
	public currentCardName = 'none';
	public updateAll = 'updateAll';
	public readyForBasket = false;
	public multipleProducts = false;
	public showExistingAlmBrandProducts = true;
	public showHeaderBadge = false;
}

const mapPristineSteps = (steps: Array<string>): Map<string, boolean> => {
	const map: Map<string, boolean> = new Map();
	steps.forEach((step) => {
		if (step !== STEP.ORDER) {
			map.set(step, true);
		}
	});
	return map;
};

export const mockAdditionalInfo = (model: CommonModel) => {
	Object.assign(model.additionalInfo, {
		existInsurance: 'ja',
		existingInsurance: 'Alka',
		existingInsuranceId: 'VIR172896',
		validNow: 'ja',
		skader: '0',
		rki: 'nej',
	});
};

export const mockContactInformation = (model: CommonModel) => {
	Object.assign(model.contact_information, {
		accept: false,
		cpr: '2342342323',
		customerNo: undefined,
		email: 'abklku@almbrand.dk',
		name: 'Tæøåst Testesen',
		phone: '11111111',
		subtitle: 'Test Testesen',
	});
};

export const mockPayment = (model: CommonModel) => {
	Object.assign(model.payment, {
		kontoNo: '123456',
		regNo: '1233',
	});
};

export const mockPersonInfo = (model: Model) => {
	Object.assign(model.personInfo, {
		almbrandCustomer: 'nej',
		existingAlmBrandProducts: [],
		customerAge: '56',
		address: 'Adolphsvej 19, 2820 Gentofte',
		houseNr: '19',
		// floorNr: 2,
		streetCode: '0134',
		streetName: 'Adolphsvej',
		subtitle: '56 år, Adolphsvej 19, 2820 Gentofte',
		zipCode: '2820',
		zipName: 'Gentofte',
		kvhxValue: '123123__123',
		familyMemberCount: '4 personer',
		familyMemberCountId: 4,
	});
};
